.PricingPage {
  align-items: center;
  padding-top: 30px;
  padding-bottom: 65px;
}
.pricing-container {
}

@media (max-width:723px) {

  .main-subtitle , main-header{max-width: 300px; text-align: center;}
  
}