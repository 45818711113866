.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto; /* Allow the main content to take up the remaining space */
}

.MasterPage {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.logo-img {
  align-items: center;
}
/* .footer-pos{position: absolute; bottom: 0; left:0; width: 100%;} */
