.App {
  text-align: center;
  font-family: ibmplexsans-regular;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.main-header {
  color: #5a5a5a;
  font-family: ibmplexsans-semibold;
  text-align: center;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.main-header-white {
  color: #FFF;
text-align: center;
font-family: ibmplexsans-semibold;
font-size: 2.75rem;
font-style: normal;
font-weight: 600;
line-height: 3.0625rem; /* 111.364% */
}
.main-subtitle {
  color: #5a5a5a;
  text-align: center;
  font-size: 1.08438rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.5rem;
}
.main-subtitle-white {
  font-family: ibmplexsans-medium;
  color: #fff;
  text-align: center;
  font-size: 1.08438rem;
  line-height: normal;
  padding: 0.5rem;
}
.seperator {
  margin-top: 5px;
  margin-bottom: 3px;
  width: 100%;
  height: 0.75px;
  background-color: #ececec;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.green-cta {
  /* width: 20rem; */
  height: 2.6875rem;
  flex-shrink: 0;
  margin-top: 15px;
  border-radius: 0.25rem;
  background: #51BB62;
  color: white;
  font-family: 'ibmplexsans-medium';
  font-size: 1.1rem
}

.blue-cta{
  text-align: center;
    background: #124ce2;
    font-size: 1.1rem;
    color: white;
    font-weight: bold;
    padding: 1.2rem;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    
}
.blue-cta a{
  text-decoration: none;
  color: white;
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
