.HowItWorksSection {
  padding: 1rem;
}
@media (max-width:723px) {

  .hiw-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 2rem;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
img{
  min-width: 100%;
  max-width: 300px;
}
button{max-width: 300px; }
  
}
.hiw-container {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  padding: 2rem;
}
.hiw-subcontainer-right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.hiw-stepcontainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.hiw-step-img {
  padding: 1rem;
}

.hiw-step-title {
  color: #0050b3;
  font-family: ibmplexsans-semibold;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.hiw-step-subtitle {
  color: #515151;
  font-family: ibmplexsans-regular;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
