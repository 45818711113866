@font-face {
  font-family: ibmplexsans-thinitalic;
  src: url('./fonts/IBMPlexSans-ThinItalic.ttf');
}

@font-face {
  font-family: ibmplexsans-bold;
  src: url('./fonts/IBMPlexSans-Bold.ttf');
}

@font-face {
  font-family: ibmplexsans-bolditalic;
  src: url('./fonts/IBMPlexSans-BoldItalic.ttf');
}

@font-face {
  font-family: ibmplexsans-extralight;
  src: url('./fonts/IBMPlexSans-ExtraLight.ttf');
}

@font-face {
  font-family: ibmplexsans-extralightitalic;
  src: url('./fonts/IBMPlexSans-ExtraLightItalic.ttf');
}

@font-face {
  font-family: ibmplexsans-italic;
  src: url('./fonts/IBMPlexSans-Italic.ttf');
}

@font-face {
  font-family: ibmplexsans-light;
  src: url('./fonts/IBMPlexSans-Light.ttf');
}

@font-face {
  font-family: ibmplexsans-lightitalic;
  src: url('./fonts/IBMPlexSans-LightItalic.ttf');
}

@font-face {
  font-family: ibmplexsans-medium;
  src: url('./fonts/IBMPlexSans-Medium.ttf');
}

@font-face {
  font-family: ibmplexsans-mediumitalic;
  src: url('./fonts/IBMPlexSans-MediumItalic.ttf');
}

@font-face {
  font-family: ibmplexsans-regular;
  src: url('./fonts/IBMPlexSans-Regular.ttf');
}

@font-face {
  font-family: ibmplexsans-semibold;
  src: url('./fonts/IBMPlexSans-SemiBold.ttf');
}

@font-face {
  font-family: ibmplexsans-semibolditalic;
  src: url('./fonts/IBMPlexSans-SemiBoldItalic.ttf');
}

@font-face {
  font-family: ibmplexsans-thin;
  src: url('./fonts/IBMPlexSans-Thin.ttf');
}

body {
  margin: 0;
  font-family:
    ibmplexsans-regular,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
