.JobSeekerSidebar {
  font-family: ibmplexsans-regular;
  max-width: 300px;
  padding: 0.5rem;
  border-right: 1px solid #e2e2e2;
  padding: 0.5rem;
}
.title {
  color: #0050b3;
}

.jobseeker-sidebar-header{
  color: #474747;
  font-family: 'ibmplexsans-medium';
  padding-bottom: 1.1rem;
}
.jobseeker-profile-container {
  display: flex;
    flex-direction: row;
    gap: 0.8rem;
    align-items: center;
}
.jobseeker-name{
  font-family: ibmplexsans-bold;
  font-size: 1rem;
}
.profile-image {
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
  background-color: rgb(211, 209, 209);
  border-radius: 1.5rem;
}
.jobseeker-jobsapplied{
  font-family: ibmplexsans-bold;
  font-size: 1.5rem;
  color: #0a6dd9;
}

.jobseeker-plan{
  border-radius: 4px;
  width: 100%;
  height: 24px;
  /* font-size: larger; */
  text-transform: uppercase;
  color: #0a6dd9;
  background-color: #0a6dd91f;
  padding: 0.2rem;
  font-family: ibmplexsans-bold;
  border: 3px solid #0a6dd9;
}

.jobseeker-membership{
  text-align: center;
}
