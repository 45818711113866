.LandingPageHeader {
}
.menu-header {
  display: flex
;
    text-align: center;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}
.menu-header ul {
  list-style-type: none;
  margin: 0;
  overflow: hidden;
}
.menu-header li {
  float: left;
  text-align: center;
  display: block;
  padding: 0.5rem;
  color: #096dd9;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0075rem;
}

.menu-header a {
  text-decoration: none;
  color: #096dd9;
}
.menu-header a:hover {
  color: #096dd9;
  text-decoration: underline;
}
@media (max-width:723px) {

  .menu-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}
  
}
