.Login {
}
input {
  width: 100%;
  min-height: 2rem;

  touch-action: manipulation;
}
@viewport {
  zoom: 1;
  min-zoom: 1;
  max-zoom: 1;
  user-zoom: fixed;
}
/* table {
  min-width: 100%;
}
tr td {
  padding: 1rem;
  width: 100%;
} */
button {
  width: 100%;
  margin: auto;
  min-height: 2rem;
}
h2 {
  padding: 1rem;
}
