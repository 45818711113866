.JobSeekerPicker {
}

.jobseeker-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.jobseeker-card-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.job-seeker-picker {
  padding: 24px;
  background: #fff;
  min-height: 100vh;
}

.job-seeker-picker .ant-table-wrapper {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
}

.job-seeker-picker .ant-table-thead > tr > th {
  background: #fafafa;
}

.job-seeker-picker .ant-tag {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
}

.job-seeker-picker .ant-space {
  width: 100%;
}

/* Status tag colors */
.job-seeker-picker .ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.job-seeker-picker .ant-tag-green {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.job-seeker-picker .ant-tag-red {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}

/* Responsive styles */
@media (max-width: 768px) {
  .job-seeker-picker {
    padding: 16px;
  }
  
  .job-seeker-picker .ant-space {
    gap: 8px !important;
  }
}
