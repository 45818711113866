.SeeDailyProgressSection {
  padding-top: 1.5rempx;
  padding-bottom: 65px;
  padding-left: 1.5rem;
  background-color: #0050B3;
  padding: 1.5rem;
  padding-bottom: 65px;
  width: 100%;
  border-radius: 4px;
}
@media (max-width:723px) {

  .SeeDailyProgressSection {
    padding-top: 1.5rem;
    background-color: #0050B3;
    width: 90%;
    border-radius: 4px;
    margin: auto;
  }
  .main-header-white,.main-subtitle-white{
    max-width: 300px;
  }

  .daily-progress-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
  }
  img{
    min-width: 100%;
    max-width: 300px;
  }
  button{max-width: 300px; }
  
  
  
}
.daily-progress-container {
  display: flex;
  flex-direction: row;
  align-items: top;
  gap: 30px;
  /* margin-right: -80; */
}
.daily-progress-cta {
  color: #5a5a5a;
  text-align: left;
  width: 25.25rem;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
