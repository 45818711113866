.RecruiterView {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.RecruiterView .ant-layout {
  min-height: 100vh;
}

.RecruiterView .ant-layout-header {
  height: 80px;
  padding: 0 24px;
  display: flex;
  align-items: center;
}

.RecruiterView .ant-layout-sider {
  background: #fff;
}

.RecruiterView .ant-layout-content {
  margin: 0;
  min-height: 280px;
}

.RecruiterView .recruiter-actions {
  margin-top: 20px;
}

.RecruiterView .content-header {
  margin-bottom: 24px;
}

.RecruiterView .ant-layout-sider-children {
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.recruiter-actions {
  margin-top: 24px;
  padding: 16px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.recruiter-actions .ant-btn {
  margin-bottom: 12px;
}

.recruiter-actions .ant-btn:last-child {
  margin-bottom: 0;
}

.ant-modal-body {
  max-height: 70vh;
  overflow-y: auto;
}

.recruiter-view {
  background: #fff;
  min-height: 100vh;
}

.recruiter-view .ant-table-wrapper {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
}

.recruiter-view .ant-table-thead > tr > th {
  background: #fafafa;
}

.recruiter-view .ant-tag {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
}

.recruiter-view .ant-space {
  width: 100%;
}

.recruiter-view .ant-table-row:hover {
  cursor: pointer;
}

/* Responsive styles */
@media (max-width: 768px) {
  .RecruiterView .ant-layout-sider {
    position: fixed;
    left: 0;
    top: 80px;
    height: calc(100vh - 80px);
    z-index: 1;
  }
}
